import logo from "./logo.svg";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";

import LandingPage from "./components/LandingPage";
import ProfilePage from "./components/ProfilePage";
import GameEntryPage from "./components/GameEntryPage";
import { useState } from "react";
import LoginPage from "./components/LoginPage";
import Layout from "./components/Layout";
import { usergames } from "./assets/GameStructure";


function App() {
  document.title = "cedhmeta";

  const [token, setToken] = useState(false);
  const [user, setUser] = useState({
    id: "jspdofjs90dfu439d0vux9",
    first_name: "John",
    last_name: "Doe",
    email: "jdoe@example.com",
    username: "joemanna",
    games_played: 44,
    wins: 19,
    losses: 1,
    profile_image: "holyogar.jpg",
  });
  const [games, setGames] = useState(usergames);
  

  function addGame(newgame) {
    let temp = [...games];
    temp.unshift(newgame);
    setGames(temp);
  }


  const commanders = JSON.parse(localStorage.getItem("commander"));
  const companions = JSON.parse(localStorage.getItem("companion"));

  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <BrowserRouter>
        {token && ( //these are the routes you have when you sign in
          <Layout user={user} setToken={setToken}>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route
                path="/profile"
                element={<ProfilePage user={user} games={games}/>}
              />

              <Route path="/game_entry" element={<GameEntryPage user={user} commanders={commanders} companions={companions} addGame={addGame}/>} />

            </Routes>
          </Layout>
        )}
        {!token && ( // these are the routes you have when not signed in
        <Layout user={user}>
          <Routes>
            <Route path="/" element={<Navigate to={`/login`} />} />
            <Route
              path="/login"
              element={<LoginPage setToken={setToken}></LoginPage>}
            />
            {/* <Route path="/register" element={ProfilePage} /> */}
          </Routes>
          </Layout>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
