import React, { useState } from "react";
import styles from "./LoginPage.module.scss";
import TextBox from "./TextBox";
import Button from "./Button";
import logo from "../assets/images/cedhmetalogoDark.jpeg";
import { useNavigate } from "react-router-dom";

function LoginPage({ setToken }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  function changeroute() {
    navigate("/profile");
    setToken(true);
  }

  return (
    <div className={styles.page}>
      <div className={styles.logo}>
        {" "}
        cedhmeta.com <img src={logo}></img> powered by prowess
      </div>

      <div className={styles.content}>
        <TextBox
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="username"
        />
        <TextBox
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="password"
          type="password"
        />
      </div>
      <div className={styles.flexbox}>
        <span className={styles.link}>Forgot Password</span>
      </div>
      <Button style={{ marginTop: "20px" }} onClick={changeroute}>
        {" "}
        Submit
      </Button>
    </div>
  );
}

export default LoginPage;
