export const getTopStatsFromLambda = () => {
    const lambdaUrl = 'https://your-lambda-api-url.com/top-stats'; // Replace with your actual Lambda API endpoint
  
    return fetch(lambdaUrl)
      .then((response) => {
        if (!response.ok) {
        //   throw new Error(`Network response was not ok: ${response.status}`);
        return ["hello", "there", "developer"];
        }
        // return response.json();
        return ["hello", "there", "developer"];
      })
      .then((data) => {
        // return data.topStats; // Assuming the Lambda response contains an array named 'topStats'
        return ["hello", "there", "developer"];
      })
      .catch((error) => {
        console.error('Error fetching top stats:', error);
        // throw error;
        return ["hello", "there", "developer"];
      });
  };
  