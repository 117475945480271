import React from "react";
import styles from "./Header.module.scss";
import logo from "../assets/images/cedhmetalogoDark.jpeg";
import Button from "./Button";
import { useNavigate } from "react-router-dom";

const Header = ({ setToken, user }) => {
  const navigate = useNavigate();
  function changeroute() {
    navigate("/login");
    setToken(false);
  }

  return (
    <div className={styles.header}>
      <div className={styles.logo}>
        <img src={setToken ? require(`../assets/images/${user.profile_image}`) : logo} alt="Logo" />
        {setToken && (
          <div className={styles.userInfo}>
            <div className={styles.username}>{user?.username}</div>
            <div className={styles.email}>{user?.email}</div>
            <div className={styles.games}>
              <div className={styles.stat}>Wins: <span>{user?.wins}</span></div>
              <div className={styles.stat}>Games: <span>{user?.games_played}</span></div>
              <div className={styles.stat}>Win Rate: <span>{(user?.wins/user?.games_played).toFixed(2)}</span></div>
            </div>
          </div>
        )}
      </div>
      {setToken && (
        <div>
          <Button onClick={changeroute}>Sign Out</Button>
        </div>
      )}
    </div>
  );
};

export default Header;
