import React, { useState } from "react";
import styles from "./Player.module.scss";
import commander from "../assets/images/jud-9-commander-eesha.jpg";
import Modal from "./Modal";
import { trimDate } from "./GameBlock";
// import profilepic from '../assets/images/commander1.png'

function Player({ player, game, user }) {
  const [showMul, setShowMul] = useState(false);
  const [cards, setCards] = useState();
  let date = new Date(game?.date);

  async function getCardArt(mulligan) {
    if (!mulligan) {
      return "";
    }
    let cards_temp = [];
    let keys = Object.keys(player?.mulligan);
    for (let name of keys) {
      if (name.includes("card")) {
        let card_name = player?.mulligan[name];
        let api_name = card_name.replaceAll(" ", "+");
        if (api_name) {
          let card_info = await fetchData(
            `https://api.scryfall.com/cards/named?fuzzy=${api_name}`
          );
          let link = card_info?.image_uris?.border_crop;
          cards_temp.push({ name: card_name, card_art: link });
        }
      }
    }
    if (cards_temp.length > 0) {
      setCards(cards_temp);
    }
  }

  function checkPlace(place) {
    if (place === 1) {
      return "1st";
    }
    if (place === 2) {
      return "2nd";
    }
    if (place === 3) {
      return "3rd";
    }
    return "4th";
  }

  return (
    <>
      <div
        className={`${styles.playerContainer} ${
          player.place === 1 && styles.gold
        }
      ${player.place === 2 && styles.silver}
      ${player.place === 3 && styles.bronze}`}
      >
        <div className={styles.top}>
          {player.won && (
            <div
              className={`${styles.place} ${player.won && styles.goldPlace}`}
            >
              {" "}
              1st
            </div>
          )}
          <div className={styles.ratio}>
            {" "}
            Rate: {(player?.user?.wins / player?.user?.games_played).toFixed(2)}
          </div>
          <img
            src={player?.commander1.art ? player?.commander1.art : commander}
          ></img>
          <div className={styles.stats}>
            <div className={styles.username}>
              <img
                src={require(`../assets/images/${player?.user?.profile_image}`)}
              ></img>
              {player?.user?.username}
              <span>
                {player?.user?.username === user?.username ? "(You)" : ""}
              </span>
            </div>
            <div className={styles.stat}>Seat: {player?.seat}</div>
            <div className={styles.stat}>
              Mulligan:{" "}
              <span
                onClick={() => {
                  setShowMul(true);
                  getCardArt(getCardArt(player?.mulligan));
                }}
              >
                {checkMulligan(player?.mulliganCount)} cards
              </span>
            </div>
          </div>
        </div>
        <div className={styles.bottom}>
          <div>
            <div className={styles.colors}>
              {player?.deck?.colors.map((color, i) => (
                <div className={`${styles.mana} ${styles[color]}`} key={i}></div>
              ))}
            </div>
            <span>View Deck</span>
          </div>
          <span>View Notes</span>
        </div>
      </div>
      <Modal show={showMul} onClose={() => setShowMul(false)}>
        <div className={styles.username}>
          <img
            src={require(`../assets/images/${player?.user?.profile_image}`)}
          ></img>
          {player?.user?.username}
        </div>
        <div className={styles.top2}>
          {" "}
          <div className={styles.username} style={{ paddingLeft: "1.5em" }}>
            Mulligan
          </div>
          <span>{game?.name}</span> <span>{trimDate(date)}</span>
        </div>

        {!cards && <div>Loading...</div>}
        {showMul && cards && (
          <div className={styles.cards}>
            {cards.map((card, i) => (
              <img
                key={i}
                src={card.card_art}
                style={{ height: "250px", borderRadius: "5px" }}
              />
            ))}
          </div>
        )}
        <div style={{ minHeight: "50px", width: "100%" }}></div>
      </Modal>
    </>
  );
}

export default Player;

function checkMulligan(count) {
  if (count <= 2) {
    return 7;
  } else {
    return 9 - count;
  }
}

const fetchData = async (url) => {
  //   const expirationTime = new Date().getTime() + 7 * 24 * 60 * 60 * 1000;
  try {
    const response = await fetch(url);
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Failed to fetch data:", response.status);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
