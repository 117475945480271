import React from "react";

// import CloseIcon from "../CloseIcon";
import styles from "./Modal.module.scss";
import Button from "./Button";

/**
 * A modal that is displayed on top of everything else in the page
 * @param {Function} modalStyle something
 * @param children anything inside the <ReactModal></ReactModal> will be displayed with the modal is shown/activated
 * @param {Boolean} show if this is true the modal will show
 * @param {Function} onClose a function that is called when the button is clicked. Button only shows if function is given as a parameter
 * @param {Function} onSave a function that is called when the button is clicked. Button only shows if function is given as a parameter
 * @param {String} backdropStyle something
 * @param {String} saveID something
 * @returns {React.ReactElement} a ReactModal
 */

const Modal = ({
  modalStyle,
  children,
  show,
  onClose,
  onSave,
  backdropStyle,
  saveID,
}) => {
  return (
    <>
      {show && (
        <ShowModal
          children={children}
          modalStyle={modalStyle}
          onClose={onClose}
          onSave={onSave}
          backdropStyle={backdropStyle}
          saveID={saveID}
        />
      )}
    </>
  );
};

function ShowModal({
  modalStyle,
  children,
  onClose,
  onSave,
  backdropStyle,
  saveID,
}) {
  return (
    <React.Fragment>
      <div
        style={backdropStyle}
        className={`${styles.modal} ${styles.visible}`}
      >
        <div style={modalStyle} className={styles.modal__wrap}>
          {children}
          <div className={styles.buttons}>
            {onSave && (
              <div className={styles.buttonsave} id={saveID}>
                <Button onClick={onSave}>Save</Button>
              </div>
            )}
          </div>
        </div>
        {onClose && (
          <div className={styles.buttonclose}>
            <Button onClick={onClose}>Close</Button>
          </div>
          // <i
          //   onClick={onClose}
          //   className={styles.buttonClose}
          // >X</i>
        )}
      </div>
    </React.Fragment>
  );
}

export default Modal;
