import React from "react";
import Header from "./Header"; // Your header component
import Footer from "./Footer"; // Your footer component

const Layout = ({ user, children, setToken }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        gap: "none",
      }}
    >
      <Header user={user} setToken={setToken} />
      <div style={{ height: "100%" }}>{children}</div>
      {/* <Footer /> */}
    </div>
  );
};

export default Layout;
