import React, { useEffect, useState } from 'react';
import Layout from './Layout';
import { getTopStatsFromLambda } from '../api/lambda'; // Implement this function

const LandingPage = () => {
  const [topStats, setTopStats] = useState([]);

  useEffect(() => {
    // Fetch top 10 stats from your Lambda function
    getTopStatsFromLambda().then((data) => {
      setTopStats(data);
    });
  }, []);

  return (
    <Layout user={null}> /* Pass the user state here */
        <div className="landing-page">
        <h1>Top 10 Stats</h1>
        <ul>
            {topStats.map((stat, index) => (
            <li key={index} className="stat-item">
                <div className="stat-background">
                <p>User: {stat.user}</p>
                <p>Win Rate: {stat.winRate}</p>
                <p>Most Used Commander: {stat.mostUsedCommander}</p>
                </div>
            </li>
            ))}
        </ul>
        </div>
    </Layout>
  );
};

export default LandingPage;
