export const usergames = [
  {
    name: "",
    description: "as;dfljsodpifja",
    date: "2023-10-25 10:30:00",
    id: "0fsdjf9sjdcx",
    turn_ended: 4,
    verify_needed: 0,
    game_stats: [
      {
        user: {
          username: "joemanna",
          first_name: "John",
          last_name: "Doe",
          email: "jdoe@example.com",
          id: "jspdofjs90dfu439d0vux9",
          profile_image: "holyogar.jpg",
          games_played: 2,
          wins: 1,
          losses: 1,
        },
        userId: "jspdofjs90dfu439d0vux9",
        gameId: "0fsdjf9sjdcx",
        seat: 1,
        won: false,
        mulliganCount: 3,
        mulligan: {
          card1: "Mage-Ring Network",
          card2: "Wall of Earth",
          card3: "Puppet Strings",
          card4: "Jacques le Vert",
          card5: "Inscription of Ruin",
          card6: "Urza's Bauble",
          card7: "",
          userId: "jspdofjs90dfu439d0vux9",
          gameId: "0fsdjf9sjdcx",
        },
        commander1: {
          name: "Abdel Adrian, Gorion's Ward",
          art: "https://cards.scryfall.io/art_crop/front/3/9/396f9198-67b6-45d8-91b4-dc853bff9623.jpg?1674134856",
          card_art:
            "https://cards.scryfall.io/border_crop/front/3/9/396f9198-67b6-45d8-91b4-dc853bff9623.jpg?1674134856",
        },
        commander2: "",
        companion: "",
        deckId: "pcovijsdpfoi",
        deck: {
          link: "https://...",
          colors: ["black", "red", "green"],
        },
        game_notes: "this game was a joke!",
        verified: true,
      },
      {
        user: {
          username: "thundercat",
          first_name: "Jeff",
          last_name: "stansbury",
          email: "jefferystatus@example.com",
          id: "jspdofjs90a0sdfij9",
          profile_image: "onc-38-neyali-suns-vanguard.jpg",
          games_played: 73,
          wins: 12,
          losses: 61,
        },
        userId: "jspdofjs90a0sdfij9",
        gameId: "0fsdjf9sjdcx",
        seat: 2,
        won: true,
        mulliganCount: 1,
        mulligan: {
          card1: "Reprocess",
          card2: "Krosan Wayfarer",
          card3: "Godtracker of Jund",
          card4: "Gift of Granite",
          card5: "Aven Initiate",
          card6: "Vorpal Sword",
          card7: "Molten Monstrosity",
          userId: "jspdofjs90a0sdfij9",
          gameId: "0fsdjf9sjdcx",
        },
        commander1: {
          name: "Abomination of Llanowar",
          art: "https://cards.scryfall.io/art_crop/front/4/b/4b68bc46-5591-44dd-becc-eca154066925.jpg?1631235320",
          card_art:
            "https://cards.scryfall.io/border_crop/front/4/b/4b68bc46-5591-44dd-becc-eca154066925.jpg?1631235320",
        },
        commander2: "",
        companion: "",
        deckId: "pcovijsdpfoi",
        deck: {
          link: "https://...",
          colors: ["white", "blue", "red", "green"],
        },
        gameNotes: "this game was a joke!",
        verified: false,
      },
      {
        user: {
          username: "sultaislayer",
          first_name: "Katie",
          last_name: "Jane",
          email: "kj@example.com",
          id: "jsaodfiufs9d0vux9",
          profile_image: "akh-271-companion-of-the-trials.jpg",
          games_played: 5,
          wins: 1,
          losses: 4,
        },
        userId: "jsaodfiufs9d0vux9",
        gameId: "0fsdjf9sjdcx",
        seat: 3,
        won: false,
        mulliganCount: 2,
        mulligan: {
          card1: "Sultai Soothsayer",
          card2: "The First Doctor",
          card3: "Skycloud Expanse",
          card4: "Ethereal Whiskergill",
          card5: "Niko Defies Destiny",
          card6: "Goliath Paladin",
          card7: "Deathcoil Wurm",
          userId: "jsaodfiufs9d0vux9",
          gameId: "0fsdjf9sjdcx",
        },
        commander1: {
          name: "Acolyte of Bahamut",
          art: "https://cards.scryfall.io/art_crop/front/b/0/b0260e1f-ff6a-4ef3-bef7-8c77f8413500.jpg?1674136933",
          card_art:
            "https://cards.scryfall.io/border_crop/front/b/0/b0260e1f-ff6a-4ef3-bef7-8c77f8413500.jpg?1674136933",
        },
        commander2: "",
        companion: "",
        deckId: "pcovijsdpfoi",
        deck: {
          link: "https://...",
          colors: ["white", "blue", "black", "red", "green"],
        },
        gameNotes: "this game was a joke!",
        verified: false,
      },
      {
        user: {
          username: "ebonymaster",
          first_name: "Sophie",
          last_name: "Wilcox",
          email: "swilco@example.com",
          id: "jspdofjsasodfijv9",
          profile_image: "ttsr-12-llanowar-elves.jpg",
          games_played: 12,
          wins: 4,
          losses: 8,
        },
        userId: "jspdofjsasodfijv9",
        gameId: "0fsdjf9sjdcx",
        seat: 4,
        won: false,
        mulliganCount: 3,
        mulligan: {
          card1: "Ebony Fly",
          card2: "Karvanista, Loyal Lupari",
          card3: "Samurai",
          card4: "Hazoret's Monument",
          card5: "Mutiny",
          card6: "Petradon",
          card7: "",
          userId: "jspdofjsasodfijv9",
          gameId: "0fsdjf9sjdcx",
        },
        commander1: {
          name: "Aboshan, Cephalid Emperor",
          art: "https://cards.scryfall.io/art_crop/front/8/2/82db4a41-03e8-4f0c-946c-a98fc5c9f7c8.jpg?1562919289",
          card_art:
            "https://cards.scryfall.io/border_crop/front/8/2/82db4a41-03e8-4f0c-946c-a98fc5c9f7c8.jpg?1562919289",
        },
        commander2: {
          name: "Abuelo, Ancestral Echo",
          art: "https://cards.scryfall.io/art_crop/front/4/0/40eee689-2514-421a-8056-eb7668be66ff.jpg?1698171789",
          card_art:
            "https://cards.scryfall.io/border_crop/front/4/0/40eee689-2514-421a-8056-eb7668be66ff.jpg?1698171789",
        },
        companion: "",
        deckId: "pcovijsdpfoi",
        deck: {
          link: "https://...",
          colors: ["white", "blue", "black", "red"],
        },
        gameNotes: "this game was a joke!",
        verified: false,
      },
    ],
  },
  {
    name: "",
    description: "as;0xc9jfesda",
    date: "2023-10-25 11:30:00",
    id: "0fsdjf9sjdcx",
    turn_ended: 4,
    verify_needed: 0,
    game_stats: [
      {
        user: {
          username: "gloryballer",
          first_name: "Austin",
          last_name: "Jarris",
          email: "akarris@example.com",
          id: "jspdasdfcv90es9",
          profile_image: "4ed-103-spell-blast.jpg",
          games_played: 6,
          wins: 5,
          losses: 1,
        },
        userId: "jspdasdfcv90es9",
        gameId: "0fsdjf9sjdcx",
        seat: 1,
        won: false,
        mulliganCount: 4,
        mulligan: {
          card1: "Barren Glory",
          card2: "Creeping Dread",
          card3: "Icatian Crier",
          card4: "Healer's Flock",
          card5: "Ghostly Touch",
          card6: "",
          card7: "",
          userId: "jspdasdfcv90es9",
          gameId: "0fsdjf9sjdcx",
        },
        commander1: {
          name: "Ace, Fearless Rebel",
          art: "https://cards.scryfall.io/art_crop/front/7/5/75f001a0-8393-4977-84bb-cde39740606c.jpg?1696691787",
          card_art:
            "https://cards.scryfall.io/border_crop/front/7/5/75f001a0-8393-4977-84bb-cde39740606c.jpg?1696691787",
        },
        commander2: "",
        companion: "",
        deckId: "pcovijsdpfoi",
        deck: {
          link: "https://...",
          colors: ["white", "blue", "black", "red", "green"],
        },
        gameNotes: "this game was a joke!",
        verified: true,
      },
      {
        user: {
          username: "killmonger",
          first_name: "Kory",
          last_name: "Panther",
          email: "killmonger@example.com",
          id: "jspdofjx09jfessfdc",
          profile_image: "thb-202-skola-grovedancer.jpg",
          games_played: 3,
          wins: 0,
          losses: 3,
        },
        userId: "jspdofjx09jfessfdc",
        gameId: "0fsdjf9sjdcx",
        seat: 2,
        won: false,
        mulliganCount: 1,
        mulligan: {
          card1: "Willbender",
          card2: "Gríma, Saruman's Footman",
          card3: "Goblin Warrens",
          card4: "Dance of the Manse",
          card5: "Olivia's Wrath",
          card6: "Centaur Battlemaster",
          card7: "Hostile Minotaur",
          userId: "jspdofjx09jfessfdc",
          gameId: "0fsdjf9sjdcx",
        },
        commander1: {
          name: "Acererak the Archlich",
          art: "https://cards.scryfall.io/art_crop/front/d/d/dd52d0bd-3abd-401c-9f56-ee911613da3b.jpg?1627704283",
          card_art:
            "https://cards.scryfall.io/border_crop/front/d/d/dd52d0bd-3abd-401c-9f56-ee911613da3b.jpg?1627704283",
        },
        commander2: "",
        companion: "",
        deckId: "pcovijsdpfoi",
        deck: {
          link: "https://...",
          colors: ["blue", "red", "green"],
        },
        gameNotes: "this game was a joke!",
        verified: false,
      },
      {
        user: {
          username: "viking577",
          first_name: "Micheal",
          last_name: "Goodrich",
          email: "mGoodrach@example.com",
          id: "jspdocoixnfeszefds",
          profile_image: "dtk-169-zurgo-bellstriker.jpg",
          games_played: 4,
          wins: 1,
          losses: 3,
        },
        userId: "jspdocoixnfeszefds",
        gameId: "0fsdjf9sjdcx",
        seat: 3,
        won: false,
        mulliganCount: 2,
        mulligan: {
          card1: "Lightning Bolt",
          card2: "Dandân",
          card3: "Shipwreck Looter",
          card4: "Gorilla Shaman",
          card5: "Messenger Falcons",
          card6: "Essence Infusion",
          card7: "Rampaging Spiketail",
          userId: "jspdocoixnfeszefds",
          gameId: "0fsdjf9sjdcx",
        },
        commander1: {
          name: "Anya, Merciless Angel",
          art: "https://cards.scryfall.io/art_crop/front/0/1/01eb2243-bfe3-48c6-ab16-95672356858e.jpg?1562272006",
          card_art:
            "https://cards.scryfall.io/border_crop/front/0/1/01eb2243-bfe3-48c6-ab16-95672356858e.jpg?1562272006",
        },
        commander2: "",
        companion: "",
        deckId: "pcovijsdpfoi",
        deck: {
          link: "https://...",
          colors: ["white", "blue", "black", "red"],
        },
        gameNotes: "this game was a joke!",
        verified: false,
      },
      {
        user: {
          username: "joemanna",
          first_name: "John",
          last_name: "Doe",
          email: "jdoe@example.com",
          id: "jspdofjs90dfu439d0vux9",
          profile_image: "holyogar.jpg",
          games_played: 2,
          wins: 1,
          losses: 1,
        },
        userId: "jspdofjs90dfu439d0vux9",
        gameId: "0fsdjf9sjdcx",
        seat: 4,
        won: true,
        mulliganCount: 3,
        mulligan: {
          card1: "Prince Imrahil the Fair",
          card2: "Hand of Death",
          card3: "Clan Crafter",
          card4: "Sarevok, Deathbringer",
          card5: "Undercity Plunder",
          card6: "Angel of Sanctions",
          card7: "",
          userId: "jspdofjs90dfu439d0vux9",
          gameId: "0fsdjf9sjdcx",
        },
        commander1: {
          name: "Anrakyr the Traveller",
          art: "https://cards.scryfall.io/art_crop/front/6/e/6ea09406-c65e-4ee4-9c74-0553e5110837.jpg?1673308606",
          card_art:
            "https://cards.scryfall.io/border_crop/front/6/e/6ea09406-c65e-4ee4-9c74-0553e5110837.jpg?1673308606",
        },
        commander2: "",
        companion: "",
        deckId: "pcovijsdpfoi",
        deck: {
          link: "https://...",
          colors: ["white", "black", "green"],
        },
        gameNotes: "this game was a joke!",
        verified: false,
      },
    ],
  },
];

export const userDecks = [
  {
    link: "https://...",
    colors: ["white", "black", "green"],
    commander1: {
      name: "Anrakyr the Traveller",
      art: "https://cards.scryfall.io/art_crop/front/6/e/6ea09406-c65e-4ee4-9c74-0553e5110837.jpg?1673308606",
      card_art:
        "https://cards.scryfall.io/border_crop/front/6/e/6ea09406-c65e-4ee4-9c74-0553e5110837.jpg?1673308606",
    },
    commander2: "",
    companion: "",
    name: "",
    games_played: 15,
    wins: 4,
    losses: 11,
  },
  {
    link: "https://...",
    colors: ["black", "red", "green", "white"],
    commander1: {
      name: "Anya, Merciless Angel",
      art: "https://cards.scryfall.io/art_crop/front/0/1/01eb2243-bfe3-48c6-ab16-95672356858e.jpg?1562272006",
      card_art:
        "https://cards.scryfall.io/border_crop/front/0/1/01eb2243-bfe3-48c6-ab16-95672356858e.jpg?1562272006",
    },
    commander2: "",
    companion: "",
    name: "",
    games_played: 5,
    wins: 5,
    losses: 0,
  },
  {
    link: "https://...",
    colors: ["green"],
    commander1: {
      name: "Abdel Adrian, Gorion's Ward",
      art: "https://cards.scryfall.io/art_crop/front/3/9/396f9198-67b6-45d8-91b4-dc853bff9623.jpg?1674134856",
      card_art:
        "https://cards.scryfall.io/border_crop/front/3/9/396f9198-67b6-45d8-91b4-dc853bff9623.jpg?1674134856",
    },
    commander2: "",
    companion: "",
    name: "",
    games_played: 24,
    wins: 10,
    losses: 14,
  },
];

export const blankgame = {
  description: "",
  date: "",
  id: "",
  turn_ended: null,
  verify_needed: 1,
  game_stats: [],
};

export const blankgamestat = {
  user: {
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    id: "",
    profile_image: "",
    games_played: 0,
    wins: 0,
    losses: 0,
  },
  userId: "",
  gameId: "",
  seat: 0,
  won: false,
  mulliganCount: 3,
  mulligan: {
    card1: "Ebony Fly",
    card2: "Karvanista, Loyal Lupari",
    card3: "Samurai",
    card4: "Hazoret's Monument",
    card5: "Mutiny",
    card6: "Petradon",
    card7: "",
    userId: "jspdofjsasodfijv9",
    gameId: "0fsdjf9sjdcx",
  },
  commander1: {},
  commander2: {},
  companion: "",
  deckId: "pcovijsdpfoi",
  deck: {
    link: "https://...",
    colors: ["white", "blue", "black", "red"],
  },
  gameNotes: "this game was a joke!",
  verified: false,
};
