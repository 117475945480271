import React from "react";
import styles from "./GameBlock.module.scss";
import Player from "./Player";
// import Select from "react-select";

function GameBlock({ game, user, edit, onAdd, onPlayerClick }) {
  let date = new Date(game?.date);

  return (
    <div className={styles.gameContainer}>
      <div className={styles.top}>
        {" "}
        <span>{game?.name}</span>{" "}
        {/* <div className={styles.pending}>
          {game?.verify_needed > 0 ? (
            <span>Pending Verifications: game?.verify_needed</span>
          ) : (
            ""
          )}
        </div>{" "} */}
        <span>{trimDate(date)}</span>
      </div>
      <div className={styles.players}>
        {game?.game_stats?.map((player, i) => (
          <div
            key={i}
            onClick={() =>
              onPlayerClick
                ? onPlayerClick({
                    id: 1,
                    username: player?.user?.username,
                    commanderName: player?.commander1?.name,
                    playOrder: player?.seat,
                    result: player?.won,
                    secondaryCommander: player?.commander2?.name,
                    companion: player?.companion?.name,
                  })
                : console.log("Click")
            }
            className={edit ? styles.player : ""}
          >
            <Player player={player} game={game} user={user} />
          </div>
        ))}
        {edit && game?.game_stats?.length < 4 && (
          <div className={styles.addPlayer} onClick={onAdd}>
            + add player
          </div>
        )}
      </div>
    </div>
  );
}

export default GameBlock;

// <div>
// <h2>Companion List</h2>
// <Select
//     options={options}
//     value={selectedCompanion}
//     onChange={handleSelect}
//     isSearchable
//     placeholder="Select a companion..."
// />
// {selectedCompanion && (
//     <div>
//     <h3>Selected Companion: {selectedCompanion.label}</h3>
//     <img src={selectedCompanion.png} alt={selectedCompanion.label} />
//     </div>
// )}
// </div>

export function trimDate(date) {
  let d = new Date(date);
  // debugger;
  let niceString = d.toDateString();
  // console.log(d.toLocaleTimeString())
  // console.log(niceString);
  let month = niceString.substring(4, 7);
  let day = niceString.substring(8, 10);
  day = 1 + parseInt(day);
  day = JSON.stringify(day);
  //eslint-disable-next-line no-unused-expressions
  day.charAt(0) == "0" ? (day = day.charAt(1)) : null;
  let year = niceString.substring(11);
  return month + " " + day + ", " + year + " at " + d.toLocaleTimeString();
}
