import React, { useEffect, useRef, useState } from "react";
import styles from "./GameEntryPage.module.scss";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import TextBox from "./TextBox";
import Button from "./Button";
import Modal from "./Modal";
import { blankgame, blankgamestat } from "../assets/GameStructure";
import GameBlock from "./GameBlock";

const GameEntryPage = ({ commanders, companions, addGame, user }) => {
  // const [date, setDate] = useState("");
  // const [turnAtGameEnd, setTurnAtGameEnd] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  function initDate() {
    let date = new Date();
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  const [game, setGame] = useState({ ...blankgame, date: initDate() });
  console.log(game.date);
  const [activePlayer, setActivePlayer] = useState({
    id: 1,
    username: "",
    commanderName: "",
    playOrder: "",
    result: "",
    secondaryCommander: "",
    companion: "",
  });

  const [players, setPlayers] = useState([
    {
      id: 1,
      username: "",
      commanderName: "",
      playOrder: "",
      result: "",
      secondaryCommander: "",
      companion: "",
    },
    {
      id: 2,
      username: "",
      commanderName: "",
      playOrder: "",
      result: "",
      secondaryCommander: "",
      companion: "",
    },
    {
      id: 3,
      username: "",
      commanderName: "",
      playOrder: "",
      result: "",
      secondaryCommander: "",
      companion: "",
    },
    {
      id: 4,
      username: "",
      commanderName: "",
      playOrder: "",
      result: "",
      secondaryCommander: "",
      companion: "",
    },
  ]);

  const playOrder = [1, 2, 3, 4];
  // const [verifications, setVerifications] = useState(1);

  const [showPlayerDialog, setShowPlayerDialog] = useState(false);
  const [selectedPlayerId, setSelectedPlayerId] = useState(null);

  const [showSecondCommander, setShowSecondCommander] = useState(false);
  const [showCompanion, setShowCompanion] = useState(false);

  //custom select styles
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "transparent",
      // match with the menu
      borderRadius: 10,
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#20c997" : "#555f6a",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: "#20c997",
      },
      cursor: "pointer",
      color: "blue",
    }),
    menu: (base) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 10,
      // kill the gap
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      borderRadius: 10,
    }),
    option: (base) => ({
      ...base,
      // border: `1px dotted ${colourOptions[2].color}`,
      // height: '100%',
      color: "black",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
  };

  // const modalRef = useRef(null);

  // function handleClickOutside(event) {
  //   debugger;
  //   if (modalRef?.current && event.target != modalRef.current) {
  //     document.removeEventListener("click", handleClickOutside, true);
  //     setShowPlayerDialog(false);
  //   }
  // }

  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside, true);
  //   };
  // }, []);

  const checkValidForm = () => {
    setIsFormValid(game.date && game.turn_ended);
  };

  const handleDateChange = (event) => {
    // setDate(event.target.value);
    console.log(event.target.value);
    setGame({ ...game, date: event.target.value });
    checkValidForm();
  };

  const handleTurnAtGameEndChange = (event) => {
    // setTurnAtGameEnd(event.target.value);
    setGame({ ...game, turn_ended: event.target.value });
    checkValidForm();
  };

  // const handlePlayerClick = (playerId) => {
  //   setSelectedPlayerId(playerId);
  //   setShowPlayerDialog(true);
  // };

  const handleInputChange = (field, value) => {
    // Update the field for the selected player
    setActivePlayer({ ...activePlayer, [field]: value });
    // const updatedPlayers = players.map((player) => {
    //   if (player.id === selectedPlayerId) {
    //     return { ...player, [field]: value };
    //   }
    //   return player;
    // });
    // setPlayers(updatedPlayers);
  };

  const savePlayerDetails = () => {
    // Close the dialog after saving

    // const updatedPlayers = players.map((player) => {
    //   if (player.id === activePlayer.id) {
    //     return activePlayer;
    //   }
    //   return player;
    // });

    let game_stats = [...game.game_stats];
    // debugger;
    let stat = JSON.parse(JSON.stringify(blankgamestat));
    stat.user.username = activePlayer.username;
    stat.user.profile_image = "holyogar.jpg";
    stat.user.games_played = 2;
    stat.user.wins = 1;
    stat.user.losses = 1;
    stat.seat = activePlayer.playOrder;
    stat.won = activePlayer.result === "Win" ? true : false;
    stat.commander1 = {
      name: activePlayer.commanderName,
      art: commanders.find((c) => c.name === activePlayer.commanderName)
        ?.image_uris.art_crop,
      card_art: commanders.find(
        (c) => c.name === activePlayer.commanderName
      )?.image_uris.border_crop,
    };
    stat.commander2 = {
      name: activePlayer.secondaryCommander,
      art: commanders.find(
        (c) => c.name === activePlayer.secondaryCommander
      )?.image_uris.art_crop,
      card_art: commanders.find(
        (c) => c.name === activePlayer.secondaryCommander
      )?.image_uris.border_crop,
    };

    let index = game_stats.findIndex(
      (stat) => stat?.user?.id === activePlayer.id
    );
    if (index >= 0) {
      game_stats[index] = stat;
    } else {
      game_stats.push(stat);
    }

    for (let i = 0; i < game_stats.length; i++) {
      if (i === 0) {
        game_stats[i].user.profile_image = "holyogar.jpg";
      }
      if (i === 1) {
        game_stats[i].user.profile_image =
          "onc-38-neyali-suns-vanguard.jpg";
      }

      if (i === 2) {
        game_stats[i].user.profile_image =
          "akh-271-companion-of-the-trials.jpg";
      }
      if (i === 3) {
        game_stats[i].user.profile_image = "ttsr-12-llanowar-elves.jpg";
      }
    }

    setGame({ ...game, game_stats: game_stats });

    setActivePlayer({
      id: 1,
      username: "",
      commanderName: "",
      playOrder: "",
      result: "",
      secondaryCommander: "",
      companion: "",
    });

    // setPlayers(updatedPlayers);
    setShowPlayerDialog(false);
  };

  const navigate = useNavigate();
  function changeroute(path) {
    navigate(path);
  }

  const doSubmit = () => {
    let tempgame = {...game}
    for (let i = 0; i < tempgame.game_stats.length; i++) {
      if (i === 0) {
        tempgame.game_stats[i].user.profile_image = "holyogar.jpg";
      }
      if (i === 1) {
        tempgame.game_stats[i].user.profile_image =
          "onc-38-neyali-suns-vanguard.jpg";
      }

      if (i === 2) {
        tempgame.game_stats[i].user.profile_image =
          "akh-271-companion-of-the-trials.jpg";
      }
      if (i === 3) {
        tempgame.game_stats[i].user.profile_image = "ttsr-12-llanowar-elves.jpg";
      }
    }
    // const tempgame = {
    //   date: game.date,
    //   id: "0fsdjf9sjdcx",
    //   turn_ended: game.turn_ended,
    //   verify_needed: game.verify_needed,
    //   game_stats: [
    //     {
    //       user: {
    //         username: players.find((c) => c.id === 1).username,
    //         first_name: "",
    //         last_name: "",
    //         email: "",
    //         id: "jspdofjs90dfu439d0vux9",
    //         profile_image: "holyogar.jpg",
    //         games_played: 2,
    //         wins: 1,
    //         losses: 1,
    //       },
    //       userId: "jspdofjs90dfu439d0vux9",
    //       gameId: "0fsdjf9sjdcx",
    //       seat: players.find((c) => c.id === 1).playOrder,
    //       place: 2,
    //       mulliganCount: 3,
    //       mulligan: {
    //         card1: "",
    //         card2: "",
    //         card3: "",
    //         card4: "",
    //         card5: "",
    //         card6: "",
    //         card7: "",
    //         userId: "",
    //         gameId: "",
    //       },
    //       commander1: {
    //         name: players.find((c) => c.id === 1).commanderName,
    //         art: commanders.find(
    //           (c) => c.name === players.find((c) => c.id === 1).commanderName
    //         )?.image_uris.art_crop,
    //         card_art: commanders.find(
    //           (c) => c.name === players.find((c) => c.id === 1).commanderName
    //         )?.image_uris.border_crop,
    //       },
    //       commander2: {
    //         name: players.find((c) => c.id === 1).secondaryCommander,
    //         art: commanders.find(
    //           (c) =>
    //             c.name === players.find((c) => c.id === 1).secondaryCommander
    //         )?.image_uris.art_crop,
    //         card_art: commanders.find(
    //           (c) =>
    //             c.name === players.find((c) => c.id === 1).secondaryCommander
    //         )?.image_uris.border_crop,
    //       },
    //       companion: "",
    //       deckId: "pcovijsdpfoi",
    //       deck: {
    //         link: "https://...",
    //         colors: ["black", "red", "green"],
    //       },
    //       game_notes: "this game was a joke!",
    //       verified: true,
    //     },
    //     {
    //       user: {
    //         username: players.find((c) => c.id === 2).username,
    //         first_name: "",
    //         last_name: "",
    //         email: "",
    //         id: "jspdofjs90a0sdfij9",
    //         profile_image: "onc-38-neyali-suns-vanguard.jpg",
    //         games_played: 73,
    //         wins: 12,
    //         losses: 61,
    //       },
    //       userId: "jspdofjs90a0sdfij9",
    //       gameId: "0fsdjf9sjdcx",
    //       seat: players.find((c) => c.id === 2).playOrder,
    //       place: 4,
    //       mulliganCount: 1,
    //       mulligan: {
    //         card1: "",
    //         card2: "",
    //         card3: "",
    //         card4: "",
    //         card5: "",
    //         card6: "",
    //         card7: "",
    //         userId: "",
    //         gameId: "",
    //       },
    //       commander1: {
    //         name: players.find((c) => c.id === 2).commanderName,
    //         art: commanders.find(
    //           (c) => c.name === players.find((c) => c.id === 2).commanderName
    //         )?.image_uris.art_crop,
    //         card_art: commanders.find(
    //           (c) => c.name === players.find((c) => c.id === 2).commanderName
    //         )?.image_uris.border_crop,
    //       },
    //       commander2: {
    //         name: players.find((c) => c.id === 2).secondaryCommander,
    //         art: commanders.find(
    //           (c) =>
    //             c.name === players.find((c) => c.id === 2).secondaryCommander
    //         )?.image_uris.art_crop,
    //         card_art: commanders.find(
    //           (c) =>
    //             c.name === players.find((c) => c.id === 2).secondaryCommander
    //         )?.image_uris.border_crop,
    //       },
    //       companion: "",
    //       deckId: "pcovijsdpfoi",
    //       deck: {
    //         link: "https://...",
    //         colors: ["white", "blue", "red", "green"],
    //       },
    //       gameNotes: "this game was a joke!",
    //       verified: false,
    //     },
    //     {
    //       user: {
    //         username: players.find((c) => c.id === 3).username,
    //         first_name: "",
    //         last_name: "",
    //         email: "",
    //         id: "jsaodfiufs9d0vux9",
    //         profile_image: "akh-271-companion-of-the-trials.jpg",
    //         games_played: 5,
    //         wins: 1,
    //         losses: 4,
    //       },
    //       userId: "jsaodfiufs9d0vux9",
    //       gameId: "0fsdjf9sjdcx",
    //       seat: players.find((c) => c.id === 3).playOrder,
    //       place: 3,
    //       mulliganCount: 2,
    //       mulligan: {
    //         card1: "",
    //         card2: "",
    //         card3: "",
    //         card4: "",
    //         card5: "",
    //         card6: "",
    //         card7: "",
    //         userId: "",
    //         gameId: "",
    //       },
    //       commander1: {
    //         name: players.find((c) => c.id === 3).commanderName,
    //         art: commanders.find(
    //           (c) => c.name === players.find((c) => c.id === 3).commanderName
    //         )?.image_uris.art_crop,
    //         card_art: commanders.find(
    //           (c) => c.name === players.find((c) => c.id === 3).commanderName
    //         )?.image_uris.border_crop,
    //       },
    //       commander2: {
    //         name: players.find((c) => c.id === 3).secondaryCommander,
    //         art: commanders.find(
    //           (c) =>
    //             c.name === players.find((c) => c.id === 3).secondaryCommander
    //         )?.image_uris.art_crop,
    //         card_art: commanders.find(
    //           (c) =>
    //             c.name === players.find((c) => c.id === 3).secondaryCommander
    //         )?.image_uris.border_crop,
    //       },
    //       companion: "",
    //       deckId: "pcovijsdpfoi",
    //       deck: {
    //         link: "https://...",
    //         colors: ["white", "blue", "black", "red", "green"],
    //       },
    //       gameNotes: "this game was a joke!",
    //       verified: false,
    //     },
    //     {
    //       user: {
    //         username: players.find((c) => c.id === 4).username,
    //         first_name: "",
    //         last_name: "",
    //         email: "",
    //         id: "jspdofjsasodfijv9",
    //         profile_image: "ttsr-12-llanowar-elves.jpg",
    //         games_played: 12,
    //         wins: 4,
    //         losses: 8,
    //       },
    //       userId: "jspdofjsasodfijv9",
    //       gameId: "0fsdjf9sjdcx",
    //       seat: players.find((c) => c.id === 4).playOrder,
    //       place: 1,
    //       mulliganCount: 3,
    //       mulligan: {
    //         card1: "",
    //         card2: "",
    //         card3: "",
    //         card4: "",
    //         card5: "",
    //         card6: "",
    //         card7: "",
    //         userId: "",
    //         gameId: "",
    //       },
    //       commander1: {
    //         name: players.find((c) => c.id === 4).commanderName,
    //         art: commanders.find(
    //           (c) => c.name === players.find((c) => c.id === 4).commanderName
    //         )?.image_uris.art_crop,
    //         card_art: commanders.find(
    //           (c) => c.name === players.find((c) => c.id === 4).commanderName
    //         )?.image_uris.border_crop,
    //       },
    //       commander2: {
    //         name: players.find((c) => c.id === 4).secondaryCommander,
    //         art: commanders.find(
    //           (c) =>
    //             c.name === players.find((c) => c.id === 4).secondaryCommander
    //         )?.image_uris.art_crop,
    //         card_art: commanders.find(
    //           (c) =>
    //             c.name === players.find((c) => c.id === 4).secondaryCommander
    //         )?.image_uris.border_crop,
    //       },
    //       companion: "",
    //       deckId: "pcovijsdpfoi",
    //       deck: {
    //         link: "https://...",
    //         colors: ["white", "blue", "black", "red"],
    //       },
    //       gameNotes: "this game was a joke!",
    //       verified: false,
    //     },
    //   ],
    // };
    addGame(tempgame);
    console.log("Game added");
    changeroute("/profile");
  };

  return (
    <div>
      <h2>Game Entry</h2>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: ".5em",
          paddingBottom: "30px",
        }}
      >
        <div className={styles.fieldContainer}>
          <label htmlFor="date">Date Played</label>
          <TextBox
            type="date"
            id="date"
            value={game.date}
            defaultValue={initDate()}
            onChange={handleDateChange}
            style={{ maxWidth: "100px" }}
          ></TextBox>
        </div>
        <div className={styles.fieldContainer}>
          <label htmlFor="turnAtGameEnd">Turn Count</label>

          <TextBox
            type="number"
            id="turnAtGameEnd"
            value={game.turn_ended}
            onChange={handleTurnAtGameEndChange}
            style={{ maxWidth: "50px" }}
            placeholder="#"
          ></TextBox>
        </div>
        <div className={styles.fieldContainer}>
          <label>Verifications</label>
          <select
            value={game.verify_needed}
            onChange={(e) =>
              setGame({ ...game, verify_needed: e.target.value })
            }
          >
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
          </select>
        </div>
      </form>

      {/* <div className={styles.playerGrid}>
        {players.map((player) => (
          <div
            key={player.id}
            className={styles.playerSquare}
            onClick={() => handlePlayerClick(player.id)}
          >
            {player.username ? (
              <div className={styles.filledSquare}>
                <p>Player: {player.username}</p>
                <p>Commander: {player.commanderName}</p>
                <p>Order: {player.playOrder}</p>
                <p>Result: {player.result}</p>
                {player.commanderName && (
                  <>
                    <p>Commander:</p>
                    <img
                      src={
                        commanders.find(
                          (c) => c.name === player.commanderName
                        )?.image_uris.png
                      }
                      alt="Commander"
                    />
                  </>
                )}
                {player.secondaryCommander && (
                  <>
                    <p>Commander 2:</p>
                    <img
                      src={
                        commanders.find(
                          (c) => c.name === player.secondaryCommander
                        )?.image_uris.png
                      }
                      alt="Commander"
                    />
                  </>
                )}
                {player.companion && (
                  <>
                    <p>Companion:</p>
                    <img
                      src={
                        companions.find((c) => c.name === player.companion)
                          ?.image_uris.png
                      }
                      alt="Commander"
                    />
                  </>
                )}
              </div>
            ) : (
              <div className={styles.emptySquare}>+</div>
            )}
          </div>
        ))}
      </div> */}

      <div style={{ display: "flex", justifyContent: "center" }}>
        <GameBlock
          game={game}
          user={user}
          edit
          onAdd={() => setShowPlayerDialog(true)}
          onPlayerClick={(player) => {
            setActivePlayer(player);
            setShowPlayerDialog(true);
          }}
        ></GameBlock>
      </div>
      <Modal
        show={showPlayerDialog}
        modalStyle={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {showPlayerDialog && (
          <div className={styles.playerDialog}>
            <div className={styles.header}>Player Details</div>
            <TextBox
              type="text"
              placeholder="Username"
              value={activePlayer.username}
              onChange={(e) => handleInputChange("username", e.target.value)}
            />
            <Select
              options={commanders.map((c) => ({
                label: c.name,
                value: c.name,
              }))}
              onChange={(selectedOption) =>
                handleInputChange("commanderName", selectedOption.value)
              }
              placeholder="Select Commander"
              styles={customStyles}
            />

            {!showSecondCommander && (
              <Button
                onClick={() => setShowSecondCommander(!showSecondCommander)}
                style={{ width: "100%" }}
              >
                Add 2nd Commander
              </Button>
            )}
            {showSecondCommander && (
              <div className={styles.companionContainer}>
                <Select
                  options={commanders.map((c) => ({
                    label: c.name,
                    value: c.name,
                  }))}
                  onChange={(selectedOption) =>
                    handleInputChange(
                      "secondaryCommander",
                      selectedOption.value
                    )
                  }
                  placeholder="Select 2nd Commander"
                  styles={customStyles}
                />
                <button
                  className={styles.deleteButton}
                  onClick={() => setShowSecondCommander(false)}
                >
                  <span className={styles.deleteIcon}>x</span>
                </button>
              </div>
            )}
            {!showCompanion && (
              <Button
                onClick={() => setShowCompanion(!showCompanion)}
                style={{ width: "100%" }}
              >
                Add Companion
              </Button>
            )}
            {showCompanion && (
              <div className={styles.companionContainer}>
                <Select
                  className={styles.myselect}
                  options={companions.map((c) => ({
                    label: c.name,
                    value: c.name,
                  }))}
                  onChange={(selectedOption) =>
                    handleInputChange("companion", selectedOption.value)
                  }
                  placeholder="Select Companion"
                  styles={customStyles}
                />
                <button
                  className={styles.deleteButton}
                  onClick={() => setShowCompanion(false)}
                >
                  <span className={styles.deleteIcon}>x</span>
                </button>
              </div>
            )}

            <Select
              options={playOrder.map((c) => ({ label: c, value: c }))}
              onChange={(selectedOption) =>
                handleInputChange("playOrder", selectedOption.value)
              }
              placeholder="Play Order"
              styles={customStyles}
            />
            <Select
              options={["Win", "Loss"].map((c) => ({ label: c, value: c }))}
              placeholder="Result"
              onChange={(e) => handleInputChange("result", e.value)}
              styles={customStyles}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                padding: "10px",
              }}
            >
              <Button onClick={savePlayerDetails}>Save</Button>
              <Button
                type="submit"
                disabled={isFormValid}
                onClick={() => setShowPlayerDialog(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
      </Modal>

      <div style={{ display: "flex", justifyContent: "end", padding: "1em" }}>
        <Button type="submit" disabled={isFormValid} onClick={doSubmit}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default GameEntryPage;
