import React from "react";
import styles from "./ProfilePage.module.scss";
import { isLocalStorageSupported } from "./LocalStorageUtils";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import GameBlock from "./GameBlock";
import { userDecks } from "../assets/GameStructure";

const ProfilePage = ({ user, setToken, games }) => {
  const [activePage, setActivePage] = useState(0);

  const existingCommanders = JSON.parse(localStorage.getItem("commander"));
  const existingCompanions = JSON.parse(localStorage.getItem("companion"));

  const checkExpireTime = (key) => {
    const expireTime = JSON.parse(localStorage.getItem(key));
    let doFetch = false;

    if (expireTime) {
      const expireDate = new Date(parseInt(expireTime));
      const currentTime = new Date();

      if (expireDate.getTime() > currentTime.getTime()) {
        doFetch = true;
      }
    }

    return doFetch;
  };

  // Usage for commanders
  const doCommanderFetch = checkExpireTime("commander_expire_time");

  // Usage for companions
  const doCompanionFetch = checkExpireTime("companion_expire_time");

  const navigate = useNavigate();
  function changeroute(path) {
    navigate(path);
  }

  const fetchData = async (initialUrl, storageKey) => {
    const expirationTime = new Date().getTime() + 7 * 24 * 60 * 60 * 1000;
    let url = initialUrl;
    const allData = []; // Temporary array to hold data from all pages
  
    try {
      while (url) {
        const response = await fetch(url);
  
        if (response.ok) {
          const data = await response.json();
          const filteredData = data.data.map((item) => ({
            name: item.name,
            image_uris: item.image_uris,
          }));
  
          // Append data to the temporary array
          allData.push(...filteredData);
  
          // Check if there's a next page
          if (data.next_page) {
            url = data.next_page;
          } else {
            url = null; // No more pages to fetch
          }
        } else {
          console.error("Failed to fetch data:", response.status);
          break;
        }
      }
  
      if (isLocalStorageSupported()) {
        // Save the combined data to local storage
        localStorage.setItem(storageKey, JSON.stringify(allData));
        localStorage.setItem(storageKey + "_expire_time", expirationTime.toString());
      } else {
        console.error("Local storage is not supported.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  

  //api call for getting single card https://api.scryfall.com/cards/named?fuzzy=Austere+Command

  useEffect(() => {
    if (!existingCommanders || doCommanderFetch) {
      fetchData(
        "https://api.scryfall.com/cards/search?q=is%3Acommander+game%3Apaper",
        "commander"
      );
    }
    const commanders = JSON.parse(localStorage.getItem("commander"));
    if (!existingCompanions || doCompanionFetch) {
      fetchData(
        "https://api.scryfall.com/cards/search?q=is%3Acompanion+game%3Apaper",
        "companion"
      );
    }
    const companions = JSON.parse(localStorage.getItem("companion"));
  }, []);

  return (
    <>
      <div className={styles.page}>
        <div className={styles.navbar}>
          <div
            className={`${styles.navItem} ${activePage === 0 && styles.active}`}
            onClick={() => setActivePage(0)}
          >
            Games
          </div>

          <div
            className={`${styles.navItem} ${activePage === 1 && styles.active}`}
            onClick={() => setActivePage(1)}
          >
            Decks
          </div>
        </div>
        {activePage === 0 && (
          <>
            <div className={styles.userStats}>
              {/** User information and stats can go here */}
            </div>
            <div className={styles.butContainer}>
              <Button onClick={() => changeroute("/game_entry")}>
                Enter New Game
              </Button>
            </div>

            <div className={styles.games}>
              {games?.map((game, i) => (
                <GameBlock key={i} game={game} user={user}></GameBlock>
              ))}
            </div>
          </>
        )}
        {activePage === 1 && (
          <div className={styles.deckPage}>
            {userDecks.map((deck) => (
              <Deck deck={deck}></Deck>
            ))}
            <div className={styles.butContainer}>
              <Button>Enter New Deck</Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProfilePage;

function Deck({ deck }) {
  return (
    <div className={styles.deck}>
      <div className={styles.left}>
        <div className={styles.deckname}>
          {deck?.name ? deck?.name : deck?.commander1.name + " Deck"}
        </div>
        <div className={styles.colors}>
          {deck?.colors.map((color) => (
            <div className={`${styles.mana} ${styles[color]}`}></div>
          ))}
        </div>
        <div>
          <div>Wins: {deck?.wins}</div>
          <div>Games: {deck?.games_played} </div>
        </div>
        <div className={styles.ratio}>
          Win Rate:{" "}
          {deck?.games_played > 0
            ? (deck?.wins / deck?.games_played).toFixed(2)
            : "n/a"}
        </div>
   
      </div>

      <img
        src={deck?.commander1.art ? deck?.commander1.art : ""}
        alt="No Image"
      ></img>
    </div>
  );
}
